
import Vue from 'vue';

export default Vue.extend({
  name: 'v-select',
  props: {
    name: {
      type: String,
      default: null,
      required: true,
    },
    label: {
      type: String,
      default: null,
    },
    defaultValue: {
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: null,
    },
    range: {
      type: Array,
      default() {
        return [];
      },
      required: true,
    },
  },
  data(): any {
    return {
      content: '',
      inputName: '',
    };
  },
  methods: {
    handleInput(val: string) {
      this.$emit('input', val);
    },
    autoSave() {
      this.$emit('auto-save');
    },
  },
  watch: {
    defaultValue: function() {
      this.content = this.defaultValue;
      this.handleInput(this.content);
    },
    name: function() {
      this.inputName = this.name;
    },
  },
  mounted() {
    if (this.defaultValue) {
      this.content = this.defaultValue;
    }

    if (this.name) {
      this.inputName = this.name;
    }
  },
});
